import type { operations } from '@/types/schemas/api.JS-3476.d.ts';
import { PATCH, setParams } from '../../_http';
type controllerV2 = operations['BrokerApplicationsSubmitApplicantsControllerV2_patch'];

export type BrokerApplicationsSubmitApplicantsBodyDtoV2 = controllerV2['requestBody']['content']['application/json'];
export type BrokerApplicationsSubmitApplicantsResponseDtoV2 =
  controllerV2['responses']['200']['content']['application/json'];
export type BrokerApplicationsSubmitApplicantsQueryDtoV2 = controllerV2['parameters']['query'];

export const submitApplicantsV2 = PATCH(
  '/v2/brokers/applications/submit-applicants',
  setParams<BrokerApplicationsSubmitApplicantsQueryDtoV2>,
)<BrokerApplicationsSubmitApplicantsBodyDtoV2, BrokerApplicationsSubmitApplicantsResponseDtoV2>;

export const submitApplicants = async (
  submitApplicantsQuery: BrokerApplicationsSubmitApplicantsQueryDtoV2,
  submitApplicantsBody: BrokerApplicationsSubmitApplicantsBodyDtoV2,
): Promise<BrokerApplicationsSubmitApplicantsResponseDtoV2> => {
  const { data } = await submitApplicantsV2(submitApplicantsQuery, submitApplicantsBody);
  return data;
};
